import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { isMobile, isIOS } from '../../utils';

import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useAppBackground from '../../hooks/useAppBackground/useAppBackground';
import useNoiseGif from '../../hooks/useNoiseGif/useNoiseGif';

import LocalVideoPreview from '../PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import MediaErrorSnackbar from '../PreJoinScreens/MediaErrorSnackbar/MediaErrorSnackbar';

import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import IconArrowRight from '../Buttons/IconArrowRight';

import './blink.css';
import './fade.css';

import { Chat } from '../../utils/vibeChat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      background: 'white',
      height: '100%',
      width: '100vw',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
    mobBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    // prada: {
    //   backgroundImage: `url(${PradaBackgroundImage})`,
    // },
    videoBg_video: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      '&.mobile-only': {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
          display: 'block',
        },
      },
      '&.desktop-only': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
    noise: {
      // backgroundImage: `url(${noiseGif})`,
      // backgroundSize: 'contain',
    },
    toolbar: {
      position: 'relative',
      height: '100vh',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    fab: {
      margin: '0',
      '&$disabledFab': {
        color: '#000000',
      },
    },
    disabledFab: {},
    fabBlack: {
      backgroundColor: '#000000',
      color: '#ffffff',
      [theme.breakpoints.down('xs')]: {
        '&:hover': {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    fabNext: {
      width: '40px',
      height: '40px',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        background: '#000',
        opacity: '0',
        pointerEvents: 'none',
        transition: 'opacity 0.2s ease-in',
        zIndex: '1',
      },
      '&.darkened:before': {
        opacity: '0.4',
      },
    },
    textField: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '28px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingTop: '14px',
      paddingBottom: '14px',
      paddingLeft: '28px',
      paddingRight: '28px',
      width: '400px',
      height: '56px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 40px)',
      },
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    nextButton: {
      backgroundColor: '#000000',
      position: 'absolute',
      right: '41px',
      transform: 'translateY(2px)',
      [theme.breakpoints.down('xs')]: {
        right: '17px',
      },
    },
    joinButton: {
      // backgroundColor: 'rgba(0,0,0,0)',
      margin: '8px',
      position: 'relative',
      transition: 'transform 0.2s ease-in',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    arrowIcon: {
      width: '18px',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#FFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '30px',
      fontWeight: 500,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        padding: '12px 20px 11px',
      },
    },
    tooltipArrow: {
      color: '#FFF',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    textInput: {
      color: '#000',
      fontSize: '20px',
      fontWeight: 500,
      padding: '4px 0 0',
      '&::placeholder': {
        color: 'black',
        opacity: '1',
      },
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
    controls: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 30px)',
      bottom: '80px',
      zIndex: 1,
      opacity: 1,
      visibility: 'visible',
      maxWidth: 'min-content',
      [theme.breakpoints.down('xs')]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`,
      },
    },
    infoContainer: {
      width: '100vw',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.down('xs')]: {
        display: 'none',
        height: 'auto',
        // display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        paddingBottom: '10px',
        position: 'absolute',
        bottom: '0',
        top: 'auto',
      },
    },
    name: {
      fontSize: 36,
      fontWeight: 300,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '48px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        bottom: '0',
        padding: '0',
        position: 'relative',
      },
    },
    city: {
      fontSize: 18,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '0',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        right: '0',
        margin: '0',
        position: 'relative',
        padding: '0',
        paddingLeft: '20px',
      },
    },
    localVideo: {
      width: '100vw',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'relative',
      },
    },
  })
);

// const LightTooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))(Tooltip);

interface ParamTypes {
  URLLocation: string;
  URLRoomName: string;
  ParticipantRole: string;
}

export default function ParticipantForm(props: { invalidLocation: boolean }) {
  const classes = useStyles();
  const { URLLocation, URLRoomName, ParticipantRole } = useParams<ParamTypes>();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks, getAudioAndVideoTracks, setShowSettings } = useVideoContext();

  const { invalidLocation } = props;

  const background = useAppBackground();
  const noiseGif = useNoiseGif();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const [blinkingReady, setBlinkingReady] = useState(false);
  const [blink, setBlink] = useState(false);
  const [mediaError, setMediaError] = useState<Error>();

  const [enteringRoom, setEnteringRoom] = useState(false);

  // step 0 = name, step 1 = city, step 2 = preview
  // const totalStep = 2;
  const [step, setStep] = useState('name');

  const isFormDisabled = isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching;

  const skipCity = process.env.REACT_APP_SKIP_CITY && process.env.REACT_APP_SKIP_CITY.toLowerCase() === 'true';

  const vibeChatOn =
    process.env.REACT_APP_VIBECHAT &&
    process.env.REACT_APP_VIBECHAT.toLowerCase() === 'true' &&
    ((ParticipantRole !== 'v' && !window.location.search.toLowerCase().includes('vibechat=false')) ||
      (ParticipantRole === 'v' && window.location.search.toLowerCase().includes('vibechat=true')));

  const customHostUrlParam = process.env.REACT_APP_HOST_URL_PARAM;
  const customModelUrlParam = process.env.REACT_APP_MODEL_URL_PARAM;

  const isAdminUrl = customHostUrlParam ? ParticipantRole === customHostUrlParam : ParticipantRole === 's';
  const isModelUrl = customModelUrlParam ? ParticipantRole === customModelUrlParam : ParticipantRole === 'm';

  const invalidUserUrl =
    ParticipantRole && ParticipantRole !== 'v' && ParticipantRole !== 'c' && !isAdminUrl && !isModelUrl;

  // console.log(process.env.REACT_APP_LOCATION_BACKGROUNDS);
  // console.log(JSON.parse(process.env.REACT_APP_LOCATION_BACKGROUNDS));

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  // console.log('video', process.env.REACT_APP_BACKGROUND_VIDEO);

  // const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRoomName(event.target.value);
  // };

  const handleCityNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCityName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement> | null) => {
    if (event) event.preventDefault();

    const btn = document.getElementById('submit') as HTMLInputElement;
    if (btn) btn.disabled = true;

    setEnteringRoom(true);

    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`/${URLLocation}/${roomName}/${ParticipantRole || 'c'}${window.location.search || ''}`)
      );
    }

    const d = {
      name: name,
      city: cityName,
      role: isModelUrl ? 'model' : isAdminUrl ? 'admin' : ParticipantRole === 'v' ? 'ghost' : 'customer',
      ms: `${Date.now()}`.substring(9),
      stream: false,
      mob: isMobile || isIOS,
      chat: Boolean(vibeChatOn),
      // dom: window.location.hostname,
      // inst: URLLocation,
    };

    // console.log('user', d);

    if (window.location.search.toLowerCase().includes('stream=true') && d.role === 'admin') {
      d.stream = true;
    }

    const djson = JSON.stringify(d);

    // console.log(djson, djson.length);
    getToken(djson, `${window.location.hostname}/${URLLocation}/${roomName}`).then(token => {
      if (vibeChatOn) {
        const chat = new Chat(name, `${window.location.hostname}/${URLLocation}/${roomName}`, 'vibe-chat-trigger', {
          ask: d.role !== 'admin' && d.role !== 'ghost',
          host: d.role === 'admin' || d.role === 'ghost',
          persistMins:
            process.env.REACT_APP_CHAT_PERSIST && typeof parseInt(process.env.REACT_APP_CHAT_PERSIST) === 'number'
              ? parseInt(process.env.REACT_APP_CHAT_PERSIST)
              : undefined,
        });
        chat.init();
      }
      // setTimeout(() => {
      // }, 200000000)
      connect(token, d);
    });
  };

  const handleChange = () => {
    if (step === 'name') {
      if (ParticipantRole === 'v') {
        console.log('here');
        const isGhost = true;
        getAudioAndVideoTracks(isGhost)
          .then(() => {
            handleSubmit(null);
          })
          .catch(error => {
            console.log('Error acquiring local media:');
            console.dir(error);
            setMediaError(error);
          });
        return;
      }

      if (skipCity) setStep('preview');
      else setStep('city');

      return;
    }

    if (step === 'preview' || (step === 'city' && ParticipantRole === 'v')) {
      handleSubmit(null);
      return;
    }

    if (step === 'city') {
      setStep('preview');
    }
  };

  useEffect(() => {
    let mounted = true;
    // console.log('change in step', step === 2);
    let timeout: NodeJS.Timeout;
    let interval: NodeJS.Timeout;

    if (step === 'preview') {
      // create interval that sets the blinking effect on

      const addInterval = () => {
        timeout = setTimeout(() => {
          setBlinkingReady(true);
          setBlink(true);
          if (!mounted) return;
          interval = setInterval(() => {
            setBlink(false);
            setTimeout(() => {
              setBlink(true);
            }, 800);
          }, 2000);
        }, 4000);
      };

      addInterval();

      // const handleMouseMove = (e: any) => {
      //   setTimeout(() => {
      //     setBlinkingReady(false);
      //     clearInterval(interval);
      //     addInterval();
      //   }, 1000);
      // };

      // const enterButton = document.getElementById('submit');

      // enterButton && enterButton.addEventListener('mouseenter', handleMouseMove);
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
      mounted = false;
    };
  }, [step, blinkingReady, blink]);

  useEffect(() => {
    if (step === 'preview' && ParticipantRole !== 'v' && !mediaError) {
      getAudioAndVideoTracks()
        .then(() => {
          setShowSettings(true);
        })
        .catch(error => {
          console.log('Error acquiring local media:');
          console.dir(error);
          setMediaError(error);
        });
    }
  }, [step, getAudioAndVideoTracks, ParticipantRole, setShowSettings, mediaError]);

  useEffect(() => {
    const handleKeydown = (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };

    const handleKeyup = (e: any) => {
      if (e.keyCode === 13) {
        if (!isFormDisabled) handleChange();
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);

    return function cleanup() {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  });

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  let textElement =
    invalidLocation || invalidUserUrl || !URLRoomName || !URLLocation ? (
      <TextField
        disabled
        placeholder={'Invalid URL'}
        className={classes.textField}
        margin="dense"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.textInput,
          },
        }}
      ></TextField>
    ) : (
      <TextField
        autoFocus
        id="menu-name"
        placeholder="Name"
        className={classes.textField}
        value={name}
        onChange={handleNameChange}
        margin="dense"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.textInput,
          },
        }}
      />
    );

  if (step === 'city')
    textElement = (
      <TextField
        autoFocus
        id="menu-city"
        placeholder="City"
        className={classes.textField}
        value={cityName}
        onChange={handleCityNameChange}
        margin="dense"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.textInput,
          },
        }}
      />
    );

  return (
    <>
      {mediaError && <MediaErrorSnackbar error={mediaError} />}
      <AppBar
        className={clsx(classes.container, {
          appbar: true,
        })}
        style={{
          backgroundImage: `url(${step === 'preview' ? noiseGif : background.imgBackground})`,
        }}
        position="static"
      >
        {/* MOBILE IMAGE BACKGROUND */}
        {(background.locationBackground && background.imgBackgroundMob) ||
        (background.imgBackgroundMob && !background.locationBackground && !background.vidBackground) ? (
          <div
            className={classes.mobBackground}
            style={{
              backgroundImage: `url(${step === 'preview' ? noiseGif : background.imgBackgroundMob})`,
            }}
          ></div>
        ) : (
          <></>
        )}

        {/* DESKTOP VIDEO BACKGROUND */}
        {background.vidBackground && !background.locationBackground ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            className={clsx(classes.videoBg_video, {
              'desktop-only': background.vidBackgroundMob,
            })}
            src={background.vidBackground}
          ></video>
        ) : (
          <></>
        )}

        {/* MOBILE VIDEO BACKGROUND */}
        {background.vidBackgroundMob && !background.locationBackground ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            className={clsx(classes.videoBg_video, 'mobile-only')}
            src={background.vidBackgroundMob}
          ></video>
        ) : (
          <></>
        )}

        <form className={clsx(classes.form, { darkened: blinkingReady })} onSubmit={handleSubmit}>
          {step === 'preview' ? (
            <div className={classes.localVideo}>
              <div className={classes.infoContainer}>
                <h4 className={classes.name}>{name}</h4>
                <h4 className={classes.city}>{cityName}</h4>
              </div>
              <LocalVideoPreview identity={name} />
              <div className={classes.controls}>
                <ToggleAudioButton />
                <ToggleVideoButton />
                <Tooltip
                  classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow, touch: classes.tooltipTouch }}
                  arrow
                  title={enteringRoom ? 'JOINING...' : 'JOIN'}
                  onOpen={() => setBlink(true)}
                  onClose={() => {
                    setBlink(false);
                  }}
                  open={blink}
                >
                  <div>
                    <Fab
                      type="submit"
                      id="submit"
                      className={`${classes.joinButton} ${classes.fab} ${classes.fabBlack}`}
                    >
                      <IconArrowRight className={classes.arrowIcon} />
                    </Fab>
                  </div>
                </Tooltip>
              </div>
            </div>
          ) : (
            <Toolbar className={classes.toolbar}>
              <SwitchTransition>
                <CSSTransition
                  key={step}
                  classNames="fade"
                  timeout={{
                    exit: 300,
                    enter: 800,
                  }}
                >
                  {textElement}
                </CSSTransition>
              </SwitchTransition>
              {!invalidLocation && URLLocation && URLRoomName && (
                <Fab
                  onClick={handleChange}
                  classes={{ root: classes.fab, disabled: classes.disabledFab }}
                  className={classes.fabBlack + ' ' + classes.fabNext + ' ' + classes.nextButton}
                  disabled={isFormDisabled || (!cityName && step === 'city')}
                >
                  <IconArrowRight className={classes.arrowIcon} />
                </Fab>
              )}

              {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
            </Toolbar>
          )}
        </form>
      </AppBar>
    </>
  );
}
