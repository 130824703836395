// import * as TwilioChat from 'twilio-chat';
import Client from 'twilio-chat';
import { Message } from 'twilio-chat/lib/message';
import { Channel } from 'twilio-chat/lib/channel';

interface Options {
  host?: boolean;
  ask?: boolean;
  persistMins?: number;
}

const timeFrom = function(datetimeString: string) {
  const date = new Date(datetimeString);
  const millisec = Math.abs(Date.now() - date.getTime());
  const minutes = Number((millisec / (1000 * 60)).toFixed(0));
  const hours = Number((millisec / (1000 * 60 * 60)).toFixed(0));
  const days = Number((millisec / (1000 * 60 * 60 * 24)).toFixed(0));
  const weeks = Number((millisec / (1000 * 60 * 60 * 24 * 7)).toFixed(0));
  const months = Number((millisec / (1000 * 60 * 60 * 24 * 7 * 4)).toFixed(0));
  const years = Number((millisec / (1000 * 60 * 60 * 24 * 7 * 4 * 12)).toFixed(1));

  if (minutes < 60) {
    return `${minutes}m`;
  }
  if (hours < 24) {
    return `${hours}h`;
  }
  if (days < 7) {
    return `${days}d`;
  }
  if (weeks < 4) {
    return `${weeks}w`;
  }
  if (months < 12) {
    return `${months}mos`;
  }

  return `${years}yrs`;
};

export class Chat {
  messages: Message[] = [];
  renderedMessages: Message[] = [];
  channel: Channel | null = null;
  token: string = '';
  container: HTMLElement | null = null;
  wrapper: HTMLElement | null = null;
  form = document.createElement('form');
  messageList: HTMLElement | null = null;
  messageInput: HTMLTextAreaElement | null = null;
  submitButton: HTMLButtonElement | null = null;
  closeButton: HTMLButtonElement | null = null;
  titleHost: HTMLElement | null = null;
  isOpen: boolean = false;
  newMessages: number = 0;
  askMode: boolean | undefined = true;
  hostMode: boolean | undefined = false;
  persistMins = 90;
  loadingBar = (() => {
    const bar = document.createElement('img');
    bar.classList.add('vibe-chat__loading');
    bar.setAttribute('src', 'https://hypno-web-assets.s3.amazonaws.com/vibe-chat-success.gif');
    return bar;
  })();
  successMessage = (() => {
    const success = document.createElement('p');
    success.classList.add('vibe-chat__success-message');
    success.innerHTML = 'SUCCESS';
    success.style.display = 'none';
    return success;
  })();
  errorMessage = (() => {
    const errorMessage = document.createElement('p');
    errorMessage.classList.add('vibe-chat__error-message');
    errorMessage.innerHTML = 'PLEASE TRY AGAIN';
    errorMessage.style.display = 'none';
    return errorMessage;
  })();

  constructor(private identity: string, private channelName: string, private triggerId: string, options?: Options) {
    if (options && options.hasOwnProperty('host')) this.hostMode = options.host;
    if (options && options.hasOwnProperty('ask')) this.askMode = options.ask;
    if (options && options.persistMins) this.persistMins = options.persistMins!;

    this.messageSubmit = this.messageSubmit.bind(this);
    document.addEventListener('click', this.handleTriggerClick);
    console.log('new chat instance', this.identity, this.channelName, this.triggerId);
  }

  async init() {
    const headers = new window.Headers();
    const params = new window.URLSearchParams({ identity: this.identity });

    this.token = await (await fetch(`/get-chat-token?${params}`, { headers })).text();
    const client = await Client.create(this.token);

    try {
      this.channel = await client.getChannelByUniqueName(this.channelName);
    } catch {
      this.channel = await client.createChannel({
        uniqueName: this.channelName,
      });
    }

    if (this.channel.status !== 'joined') {
      client.on('channelJoined', () => {
        this.registerChannelListeners();
      });
      await this.channel.join();
    } else {
      this.registerChannelListeners();
    }

    if (this.hostMode) {
      this.messages = (await this.channel.getMessages()).items;
      // only messages that have been posted in less than persistMins should be considered
      this.messages = this.messages.filter(m => (Date.now() - m.dateCreated.getTime()) / 60000 < this.persistMins);
    }

    // this.render();
    this.renderStyles();
    this.createContainer();
    this.renderMessages();

    return this;
  }

  private createContainer() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('vibe-chat__wrapper');

    this.container = document.createElement('div');
    this.container.classList.add('vibe-chat__container');
    if (this.askMode) this.container.classList.add('vibe-chat--asker');
    if (this.hostMode) this.container.classList.add('vibe-chat--host');
    this.wrapper.appendChild(this.container);

    this.closeButton = document.createElement('button');
    this.closeButton.onclick = this.hideWidget;
    this.closeButton.classList.add('vibe-chat__close-button');
    this.wrapper.appendChild(this.closeButton);

    this.titleHost = document.createElement('h2');
    this.titleHost.classList.add('vibe-chat__title');
    this.titleHost.classList.add('vibe-chat__title-host');
    this.titleHost.innerHTML = `${this.messages.length} QUESTIONS`;

    this.container.appendChild(this.titleHost);

    this.messageList = document.createElement('div');
    this.messageList.classList.add('vibe-chat__message-list');

    this.container.appendChild(this.messageList);

    this.form.classList.add('vibe-chat__form');
    this.form.onsubmit = e => {
      e.preventDefault();
      this.messageSubmit();
    };

    this.container.appendChild(this.form);

    const titleAsk = document.createElement('h2');
    titleAsk.classList.add('vibe-chat__title');
    titleAsk.classList.add('vibe-chat__title-asker');
    titleAsk.innerHTML = 'ASK A QUESTION';

    this.form.appendChild(titleAsk);

    this.messageInput = document.createElement('textarea');
    this.messageInput.classList.add('vibe-chat__message-input');

    this.form.appendChild(this.messageInput);

    this.submitButton = document.createElement('button');
    this.submitButton.innerHTML = `
      <svg class="vibe-chat__arrow" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 1.51474L17.4853 8.58581C18.2663 9.36686 18.2663 10.6332 17.4853 11.4142L10.4142 18.4853C9.63317 19.2664 8.36683 19.2664 7.58579 18.4853C6.80474 17.7043 6.80474 16.4379 7.58579 15.6569L11.2426 12H2C0.89543 12 0 11.1046 0 10C0 8.89545 0.895431 8.00002 2 8.00002H11.2426L7.58579 4.34317C6.80474 3.56212 6.80474 2.29579 7.58579 1.51474C8.36683 0.733693 9.63317 0.733693 10.4142 1.51474Z" />
      </svg>
    `;

    this.submitButton.classList.add('vibe-chat__submit-button');
    this.submitButton.setAttribute('type', 'submit');
    // this.submitButton.addEventListener('click', this.messageSubmit);

    this.form.append(this.submitButton);

    this.container.appendChild(this.successMessage);
    this.container.appendChild(this.errorMessage);

    document.body.appendChild(this.wrapper);
  }

  private renderMessages() {
    if (!this.hostMode) return;
    if (this.titleHost) this.titleHost.innerHTML = `${this.messages.length} QUESTIONS`;

    this.messages.forEach(m => {
      const minsSincePublished = (Date.now() - m.dateCreated.getTime()) / 60000;
      const hasBeenRendered = this.renderedMessages.find(rm => rm.sid === m.sid);

      if (minsSincePublished > this.persistMins) {
        if (hasBeenRendered) this.renderedMessages = this.renderedMessages.filter(rm => rm.sid !== m.sid);
        return;
      }

      if (hasBeenRendered) {
        // if message has been rendered, find the date element and update it.
        const dateTime = document.querySelector(`.vibe-chat__time-from[data-sid="${m.sid}"]`);
        dateTime!.innerHTML = timeFrom(`${m.dateCreated}`);
        return;
      }

      this.renderedMessages.push(m);

      const newMessage = document.createElement('article');
      newMessage.classList.add('vibe-chat__message');
      newMessage.innerHTML = `
        <h3 class="vibe-chat__message-author">${m.author}</h3>
        <p class="vibe-chat__time-from" data-sid="${m.sid}">${timeFrom(`${m.dateCreated}`)} ago</p>
        <p class="vibe-chat__message-body">${m.body}</p>
      `;

      this.messageList?.prepend(newMessage);
    });
  }

  private registerChannelListeners() {
    if (!this.channel) {
      return;
    }

    this.channel.on('messageAdded', (msg: Message) => {
      this.messages.push(msg);
      // console.log('new message', msg);
      if (!this.isOpen) this.newMessages += 1;
      document.dispatchEvent(new CustomEvent('vibechat_new_message', { detail: this.newMessages }));
      this.renderMessages();
    });
  }

  private messageSubmit() {
    const msg: string = this.messageInput!.value;
    if (this.channel && Boolean(msg)) {
      this.channel
        .sendMessage(msg)
        .then(() => {
          this.messageInput!.value = '';
          this.form.style.display = 'none';
          if (this.titleHost) this.titleHost.style.display = 'none';
          if (this.messageList) this.messageList.style.display = 'none';
          this.successMessage.style.display = 'block';
          setTimeout(() => {
            this.hideWidget();
            this.successMessage.style.display = 'none';
            this.form.style.display = '';
            if (this.titleHost) this.titleHost.style.display = '';
            if (this.messageList) this.messageList.style.display = '';
          }, 1200);
        })
        .catch(() => {
          this.form.style.display = 'none';
          if (this.titleHost) this.titleHost.style.display = 'none';
          if (this.messageList) this.messageList.style.display = 'none';
          this.errorMessage.style.display = 'block';
          setTimeout(() => {
            this.errorMessage.style.display = 'none';
            this.form.style.display = '';
            if (this.titleHost) this.titleHost.style.display = '';
            if (this.messageList) this.messageList.style.display = '';
          }, 2000);
        });
    }
  }

  private handleTriggerClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    // console.log('click', target.id, this.triggerId);
    if (!target || target.id !== this.triggerId) return;
    if (this.isOpen) this.hideWidget();
    else this.showWidget();
  };

  private showWidget() {
    // console.log('open');
    this.isOpen = true;
    this.newMessages = 0;
    document.dispatchEvent(new CustomEvent('vibechat_new_message', { detail: this.newMessages }));
    this.wrapper?.classList.add('open');
    if (this.askMode) this.messageInput?.focus();
  }

  private hideWidget = () => {
    // console.log('close');
    this.wrapper?.classList.remove('open');
    this.isOpen = false;
  };

  private renderStyles() {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      .vibe-chat__wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 20;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
        opacity: 0;
      }

      .vibe-chat__wrapper.open {
        opacity: 1;
        pointer-events: all;
      }

      .vibe-chat__container {
        position: absolute;
        max-width: 540px;
        max-height: 60vh;
        height: 540px;
        width: 90vw;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: #FFF;
        z-index: 20;
        border-radius: 30px;
        overflow: hidden;
      }

      .vibe-chat__container.vibe-chat--asker:not(.vibe-chat--host) {
        height: 360px;
      }

      .open .vibe-chat__container {
        overflow: auto;
      }

      .vibe-chat__title {
        margin: 30px 30px 20px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      .vibe-chat__title-asker {
        margin: 0 0 20px;
      }

      .vibe-chat__message-list {
        margin: 0 30px 30px;
      }

      .vibe-chat__form,
      .vibe-chat__title-asker {
        display: none;
      }

      .vibe-chat--asker .vibe-chat__form,
      .vibe-chat--asker .vibe-chat__title-asker {
        display: block;
      }

      .vibe-chat__message-list,
      .vibe-chat__title-host {
        display: none;
      }

      .vibe-chat--host .vibe-chat__message-list,
      .vibe-chat--host .vibe-chat__title-host {
        display: block;
      }

      .vibe-chat__form {
        position: relative;
        margin: 30px;
        padding-bottom: 60px;
        box-sizing: border-box;
      }

      .vibe-chat--asker:not(.vibe-chat--host) .vibe-chat__form {
        height: calc(100% - 65px);
      }

      .vibe-chat__message-input {
        width: 100%;
        height: 150px;
        border: none;
        resize: none;
        padding: 0;
        font-family: inherit;
        font-size: 20px;
      }

      .vibe-chat__message-input:focus {
        outline: none;
      }

      .vibe-chat__submit-button {
        display: flex;
        padding: 21px;
        border: none;
        border-radius: 100%;
        background: #000;
        cursor: pointer;
        margin-left: auto;
        margin-right: 0;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .vibe-chat__submit-button:focus {
        outline: none;
      }

      .vibe-chat__arrow {
        width: 20px;
        height: 20px;
        fill: #FFF;
      }

      .vibe-chat__message {
        margin-bottom: 25px;
      }

      .vibe-chat__message-author {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
        display: inline-block;
      }

      .vibe-chat__time-from {
        display: inline-block;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        margin: 0;
        margin-left: 3px;
        color: #ccc;
      }

      .vibe-chat__message-body {
        font-size: 20px;
        line-height: 24px;
        color: #999;
        font-weight: 500;
        margin: 0;
      }

      .vibe-chat__close-button {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        outline: none;
        border: none;
      }

      .vibe-chat__success-message,
      .vibe-chat__error-message {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
      

      @media screen and (max-width: 960px) {
        .vibe-chat__container {
          width: calc(100vw - 50px);
        }

        .vibe-chat__title {
          margin: 22px 22px 15px;
        }
  
        .vibe-chat__title-asker {
          margin: 0 0 15px;
        }

        .vibe-chat__message-list {
          margin: 0 22px 22px;
        }

        .vibe-chat__title,
        .vibe-chat__message-author,
        .vibe-chat__message-body {
          font-size: 15px;
          line-height: 18px;
        }

        .vibe-chat__time-from {
          font-size: 13px;
          line-height: 18px;
        }

        .vibe-chat__message {
          margin-bottom: 18px;
        }

        .vibe-chat__submit-button {
          padding: 18px;
        }

        .vibe-chat__arrow {
          width: 18px;
          height: 18px;
        }
      }
    `;

    document.body.appendChild(styleTag);
  }
}
