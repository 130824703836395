import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconPlay(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 28 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 11C26 11.345 25.8094 11.6637 25.5 11.8362L7.5 21.8706C7.1906 22.0431 6.8094 22.0431 6.5 21.8706C6.1906 21.6982 6 21.3794 6 21.0344V0.965564C6 0.620601 6.1906 0.301842 6.5 0.129361C6.8094 -0.0431204 7.1906 -0.0431204 7.5 0.129361L25.5 10.1638C25.8094 10.3363 26 10.655 26 11Z"
      />
    </SvgIcon>
  );
}

export default IconPlay;
