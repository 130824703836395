import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconVideoOff(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 26 22" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7071 0.392898C23.3166 0.00237351 22.6834 0.00237411 22.2929 0.392898L2.4939 20.1919C2.10338 20.5824 2.10338 21.2156 2.4939 21.6061C2.88443 21.9966 3.51759 21.9966 3.90812 21.6061L23.7071 1.80711C24.0976 1.41659 24.0976 0.783422 23.7071 0.392898ZM16.362 2.32662C16.5926 2.44413 16.8056 2.59023 16.9965 2.76045L0.979226 18.7777C0.905274 18.8517 0.83599 18.9285 0.771375 19.0079C0.596634 18.814 0.446915 18.597 0.326982 18.3616C0.134188 17.9832 0.0628723 17.59 0.0305713 17.1947C-3.17022e-05 16.8201 -1.70211e-05 16.3654 0 15.8382V15.8382V6.16109V6.16105C-1.70211e-05 5.63392 -3.17039e-05 5.17917 0.0305713 4.80461C0.0628723 4.40926 0.134188 4.01604 0.326982 3.63766C0.614602 3.07318 1.07354 2.61424 1.63803 2.32662C2.01641 2.13382 2.40963 2.06251 2.80497 2.03021C3.17954 1.9996 3.63428 1.99962 4.16142 1.99963H4.16146H13.8385H13.8386C14.3657 1.99962 14.8205 1.9996 15.195 2.03021C15.5904 2.06251 15.9836 2.13382 16.362 2.32662ZM13.8386 19.9996H8.2426L23.3334 4.90888L24.4453 4.16759C24.7522 3.96301 25.1467 3.94394 25.4719 4.11796C25.797 4.29198 26 4.63084 26 4.99964V16.9996C26 17.3684 25.797 17.7073 25.4719 17.8813C25.1467 18.0553 24.7522 18.0363 24.4453 17.8317L18 13.5348V15.8382C18 16.3653 18 16.8201 17.9694 17.1947C17.9371 17.59 17.8658 17.9832 17.673 18.3616C17.3854 18.9261 16.9265 19.385 16.362 19.6727C15.9836 19.8654 15.5904 19.9368 15.195 19.9691C14.8205 19.9997 14.3657 19.9997 13.8386 19.9996Z"
      />
    </SvgIcon>
  );
}

export default IconVideoOff;
