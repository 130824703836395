import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackgroundImage from './vibe-bg-white.jpg';

interface LocationBackgrounds {
  location: string;
  url: string;
}

interface ParamTypes {
  URLLocation: string;
}

export default function useAppBackground() {
  const [imgBackground, setImgBackground] = useState(BackgroundImage);
  const [imgBackgroundMob, setImgBackgroundMob] = useState('');

  const [vidBackground, setVidBackground] = useState('');
  const [vidBackgroundMob, setVidBackgroundMob] = useState('');

  const [locationBackground, setLocationBackground] = useState(false);

  const { URLLocation } = useParams<ParamTypes>();

  useEffect(() => {
    let locationBackgrounds: Array<LocationBackgrounds> = [];
    let locationBackgroundsMob: Array<LocationBackgrounds> = [];

    if (process.env.REACT_APP_LOCATION_BACKGROUNDS) {
      let locationBgString = process.env.REACT_APP_LOCATION_BACKGROUNDS.split(',');

      locationBgString.forEach(l => {
        const parts = l.split('|');
        locationBackgrounds = [...locationBackgrounds, { location: parts[0], url: parts[1] }];
      });
    }

    if (process.env.REACT_APP_LOCATION_BACKGROUNDS_MOBILE) {
      let locationBgMobString = process.env.REACT_APP_LOCATION_BACKGROUNDS_MOBILE.split(',');

      locationBgMobString.forEach(l => {
        const parts = l.split('|');
        locationBackgroundsMob = [...locationBackgroundsMob, { location: parts[0], url: parts[1] }];
      });
    }

    const locationBg = URLLocation && locationBackgrounds.find(l => l.location === URLLocation.toLowerCase());
    const locationBgMob = URLLocation && locationBackgroundsMob.find(l => l.location === URLLocation.toLowerCase());

    setLocationBackground(Boolean(locationBg));
    setImgBackground(locationBg ? locationBg.url : process.env.REACT_APP_BACKGROUND_IMAGE || BackgroundImage);
    setImgBackgroundMob(locationBgMob ? locationBgMob.url : process.env.REACT_APP_BACKGROUND_IMAGE_MOBILE || '');

    const videoBg = process.env.REACT_APP_BACKGROUND_VIDEO;
    videoBg && setVidBackground(videoBg);

    const videoBgMob = process.env.REACT_APP_BACKGROUND_VIDEO_MOBILE;
    videoBgMob && setVidBackgroundMob(videoBgMob);
  }, [URLLocation]);

  return { imgBackground, imgBackgroundMob, vidBackground, vidBackgroundMob, locationBackground };
}
