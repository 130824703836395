import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconMute(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5V13C14 15.7614 11.7614 18 9 18C6.23858 18 4 15.7614 4 13V5ZM17 12C17.5523 12 18 12.4477 18 13C18 17.2874 15.002 20.8747 10.988 21.7797C10.9959 21.852 11 21.9255 11 22V24C11 25.1046 10.1046 26 9 26C7.89543 26 7 25.1046 7 24V22C7 21.9255 7.00407 21.852 7.012 21.7797C2.99799 20.8747 0 17.2874 0 13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13C2 16.866 5.13401 20 9 20C12.866 20 16 16.866 16 13C16 12.4477 16.4477 12 17 12Z"
      />
    </SvgIcon>
  );
}

export default IconMute;
