import { useEffect, useState } from 'react';
// import useParticipantType from '../../../hooks/useParticipantType/useParticipantType';

export default function useHideCameras() {
  const [hideCameras, setHideCameras] = useState<boolean>(false);

  useEffect(() => {
    // if (!localDataTrackPublication) return;
    const handleHide = () => {
      setHideCameras(true);
    };
    const handleShow = () => {
      setHideCameras(false);
    };

    document.addEventListener('VibeHideAll', handleHide as EventListener);
    document.addEventListener('VibeShowAll', handleShow);

    return () => {
      document.removeEventListener('VibeHideAll', handleHide as EventListener);
      document.removeEventListener('VibeShowAll', handleShow);
    };
  }, []);

  return [hideCameras, setHideCameras] as const;
}
