import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

// import clsx from 'clsx';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useAdminDatatrackSend from '../../hooks/useAdminDatatrackSend/useAdminDatatrackSend';

import IconSpeaker from '../Buttons/IconSpeaker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100px',
      marginRight: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      background: '#000',
      borderRadius: '20px',
      padding: '5px 17px 5px',
    },
    sliderRoot: {
      top: '-1px',
    },
    track: {
      height: '4px',
      background: '#FFF',
    },
    rail: {
      height: '4px',
      background: '#FFF',
    },
    thumb: {
      display: 'none',
    },
    speaker: {
      width: '12px',
      marginRight: '7px',
      color: '#FFF',
    },
  })
);

export default function VolumeBar() {
  const {
    videoVolume,
    setVideoVolume,
    room,
    room: { localParticipant },
  } = useVideoContext();
  const [sendMessage, currentLocalDataTrack] = useAdminDatatrackSend();

  // const [localDataTrackPublication] =
  //   room.state === 'connected' ? [...room.localParticipant.dataTracks.values()] : [undefined];
  // const localDataTrack = useRef(localDataTrackPublication);

  const classes = useStyles();

  const isAdmin = room.state === 'connected' && JSON.parse(localParticipant.identity).role === 'admin';

  const sendMaxVolume = (maxVolume: number) => {
    sendMessage(`VibeMaxVolumeLock-${maxVolume}`);
  };

  const handleChange = (e: any, newValue: any) => {
    if (newValue === videoVolume.volume) return;

    if (isAdmin) {
      setVideoVolume({
        volume: newValue,
        maxVolume: newValue,
      });

      sendMaxVolume(newValue);
    } else {
      setVideoVolume(state => ({
        ...state,
        volume: newValue,
      }));
    }
  };

  useEffect(() => {
    setVideoVolume(c => ({
      ...c,
      maxVolume: 100,
    }));
  }, [setVideoVolume]);

  useEffect(() => {
    const sendCurrentStatus = () => {
      if (videoVolume.maxVolume !== 100 && currentLocalDataTrack) {
        sendMessage(`VibeMaxVolumeLock-${videoVolume.maxVolume}`, 3000);
      }
    };

    // when participants change - i.e when someone joins the room, admin sends the message of the state of the bgvideo
    room.on('participantConnected', sendCurrentStatus);

    return () => {
      room.off('participantConnected', sendCurrentStatus);
    };
  });

  return (
    <div className={classes.root}>
      <IconSpeaker className={classes.speaker}></IconSpeaker>
      <Slider
        classes={{ root: classes.sliderRoot, track: classes.track, rail: classes.rail, thumb: classes.thumb }}
        value={isAdmin ? videoVolume.maxVolume : videoVolume.volume}
        onChange={handleChange}
        valueLabelDisplay="off"
        aria-labelledby="discrete-slider"
        step={10}
        min={0}
        max={isAdmin ? 100 : videoVolume.maxVolume}
      />
    </div>
  );
}
