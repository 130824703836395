import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 26 28" {...props}>
      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0.607666" y="0" width="25" height="28" fill="white">
        <rect fill="white" x="0.607666" width="25" height="28" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3923 8L13 2L2.60767 8V20L13 26L23.3923 20V8ZM13 22C17.4182 22 21 18.4183 21 14C21 9.58172 17.4182 6 13 6C8.58169 6 4.99997 9.58172 4.99997 14C4.99997 18.4183 8.58169 22 13 22Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3923 8L13 2L2.60767 8V20L13 26L23.3923 20V8ZM13 22C17.4182 22 21 18.4183 21 14C21 9.58172 17.4182 6 13 6C8.58169 6 4.99997 9.58172 4.99997 14C4.99997 18.4183 8.58169 22 13 22Z"
        fill=""
      />
      <path
        d="M13 2L14 0.267949C13.3812 -0.0893164 12.6188 -0.0893164 12 0.267949L13 2ZM23.3923 8H25.3923C25.3923 7.28547 25.0111 6.62521 24.3923 6.26795L23.3923 8ZM2.60767 8L1.60767 6.26795C0.988864 6.62521 0.607666 7.28547 0.607666 8H2.60767ZM2.60767 20H0.607666C0.607666 20.7145 0.988864 21.3748 1.60767 21.732L2.60767 20ZM13 26L12 27.732C12.6188 28.0893 13.3812 28.0893 14 27.732L13 26ZM23.3923 20L24.3923 21.732C25.0111 21.3748 25.3923 20.7145 25.3923 20H23.3923ZM12 3.73205L22.3923 9.73205L24.3923 6.26795L14 0.267949L12 3.73205ZM3.60767 9.73205L14 3.73205L12 0.267949L1.60767 6.26795L3.60767 9.73205ZM4.60767 20V8H0.607666V20H4.60767ZM14 24.268L3.60767 18.268L1.60767 21.732L12 27.732L14 24.268ZM22.3923 18.268L12 24.268L14 27.732L24.3923 21.732L22.3923 18.268ZM21.3923 8V20H25.3923V8H21.3923ZM19 14C19 17.3137 16.3137 20 13 20V24C18.5228 24 23 19.5228 23 14H19ZM13 8C16.3137 8 19 10.6863 19 14H23C23 8.47715 18.5228 4 13 4V8ZM6.99997 14C6.99997 10.6863 9.68626 8 13 8V4C7.47712 4 2.99997 8.47715 2.99997 14H6.99997ZM13 20C9.68626 20 6.99997 17.3137 6.99997 14H2.99997C2.99997 19.5228 7.47712 24 13 24V20Z"
        fill=""
        mask="url(#path-1-outside-1)"
      />
    </SvgIcon>
  );
}

export default SettingsIcon;
