import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Track } from 'twilio-video';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions/useVideoTrackDimensions';

import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
// import classes from '*.module.css';

const Video = styled('video')({
  width: '100%',
  height: '100%',
  // maxHeight: '28vw',
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    video: {
      position: 'relative',
      objectFit: 'cover',
      [theme.breakpoints.down('xs')]: {
        objectPosition: 'center',
      },
      '.mobile--forced &': {
        objectPosition: 'center',
      },
    },
    stream: {
      objectFit: 'contain',
    },
  })
);

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  const screenShareParticipant = useScreenShareParticipant();

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const classesUi = useStyles();

  // The local video track is mirrored.
  const isPreventMirror = window.location.search.toLowerCase().includes('preventmirror=true');
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    transform: isLocal && isFrontFacing && !isPreventMirror ? 'translateX(-50%) scaleX(-1.01)' : '',
    left: isLocal && isFrontFacing && !isPreventMirror ? '50%' : '',
    objectFit: isPortrait || track.name.includes('screen') ? ('cover' as const) : ('cover' as const),
  };

  return <Video ref={ref} style={style} className={screenShareParticipant ? classesUi.stream : classesUi.video} />;
}
