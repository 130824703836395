import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconMessageBubble(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 26 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3126 18.833C20.8335 18.0246 25 14.3772 25 10C25 5.02944 19.6274 1 13 1C6.37258 1 1 5.02944 1 10C1 14.3772 5.16653 18.0246 10.6874 18.833L13 25L15.3126 18.833Z"
      />
      <path d="M15.3126 18.833L15.1677 17.8436C14.8074 17.8963 14.5042 18.1409 14.3763 18.4819L15.3126 18.833ZM10.6874 18.833L11.6237 18.4819C11.4958 18.1409 11.1926 17.8963 10.8323 17.8436L10.6874 18.833ZM13 25L12.0637 25.3511C12.21 25.7414 12.5832 26 13 26C13.4168 26 13.79 25.7414 13.9363 25.3511L13 25ZM24 10C24 13.6774 20.4303 17.073 15.1677 17.8436L15.4575 19.8225C21.2367 18.9763 26 15.0771 26 10H24ZM13 2C16.1183 2 18.9028 2.9492 20.8853 4.43604C22.8674 5.92265 24 7.90005 24 10H26C26 7.12939 24.4463 4.60679 22.0853 2.83604C19.7246 1.06552 16.5092 0 13 0V2ZM2 10C2 7.90005 3.13257 5.92265 5.11472 4.43604C7.09717 2.9492 9.88174 2 13 2V0C9.49084 0 6.27541 1.06552 3.91472 2.83604C1.55372 4.60679 0 7.12939 0 10H2ZM10.8323 17.8436C5.56975 17.073 2 13.6774 2 10H0C0 15.0771 4.76331 18.9763 10.5425 19.8225L10.8323 17.8436ZM9.75105 19.1841L12.0637 25.3511L13.9363 24.6489L11.6237 18.4819L9.75105 19.1841ZM13.9363 25.3511L16.2489 19.1841L14.3763 18.4819L12.0637 24.6489L13.9363 25.3511Z" />
    </SvgIcon>
  );
}

export default IconMessageBubble;
