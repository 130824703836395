import React from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';
import DataTrack from '../DataTrack/DataTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import { IVideoTrack } from '../../types';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocalParticipant?: boolean;
  videoOnly?: boolean;
  videoPriority?: Track.Priority | null;
}

export default function Publication({
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
  participant,
}: PublicationProps) {
  const track = useTrack(publication);
  const { hideCameras } = useVideoContext();

  const isMainParticipant = JSON.parse(participant.identity).role === 'model';

  if (!track) return null;

  const isScreenShare = track.name.includes('screen');

  switch (track.kind) {
    case 'video':
      return hideCameras && !isScreenShare && !isMainParticipant ? (
        <></>
      ) : (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocalParticipant}
        />
      );
    case 'audio':
      return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
    case 'data':
      return videoOnly ? null : <DataTrack track={track} />; // same case as audio, we only want to render the DataTrack once.
    default:
      return null;
  }
}
