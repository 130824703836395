import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconMuted(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 23 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9916 4.70693C15.8398 2.08191 13.663 0 11 0C8.23858 0 6 2.23858 6 5V13C6 13.5141 6.07758 14.01 6.22168 14.4768L15.9916 4.70693ZM4.68165 16.0168C4.24469 15.1033 4 14.0802 4 13C4 12.4477 3.55228 12 3 12C2.44772 12 2 12.4477 2 13C2 14.6378 2.4375 16.1735 3.20201 17.4965L4.68165 16.0168ZM7.77776 21.406L9.37367 19.8101C9.89556 19.9343 10.4401 20 11 20C14.866 20 18 16.866 18 13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13C20 17.2874 17.002 20.8747 12.988 21.7797C12.9959 21.852 13 21.9255 13 22V24C13 25.1046 12.1046 26 11 26C9.89543 26 9 25.1046 9 24V22C9 21.9255 9.00407 21.852 9.012 21.7797C8.5885 21.6842 8.17631 21.5589 7.77776 21.406ZM15.9966 13.1872L11.1872 17.9966C13.7998 17.9004 15.9004 15.7998 15.9966 13.1872Z"
        stroke="none"
      />
      <rect x="21.2131" y="2" width="2" height="30" rx="1" transform="rotate(45 21.2131 2)" stroke="none" />
    </SvgIcon>
  );
}

export default IconMuted;
