import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import VideoOffIcon from '../Buttons/IconVideoOff';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';

import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';

import useTrack from '../../hooks/useTrack/useTrack';
import useParticipantType from '../../hooks/useParticipantType/useParticipantType';
import Typography from '@material-ui/core/Typography';

import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      '& video': {
        filter: 'none',
        // objectFit: 'contain !important',
      },
      '& svg': {
        // stroke: 'black',
        // strokeWidth: '0.8px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
        height: 'auto',
      },
      '.mobile--forced &': {
        fontSize: '10px',
        height: 'auto',
      },
    },
    isVideoSwitchedOff: {},
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: '0',
      left: '0',
    },
    hideVideo: {
      // background: 'black',
    },
    name: {
      fontSize: 36,
      fontWeight: 300,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '48px',
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em',
    },
    city: {
      fontSize: 18,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '0',
    },
    left: {
      left: '0',
      textAlign: 'left',
      marginRight: 'auto',
    },
    right: {
      right: '0',
      textAlign: 'right',
      marginLeft: 'auto',
    },
    infoRow: {
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: '50vw',
      top: '0',
    },
    audioIndicatorWrapper: {
      position: 'absolute',
      top: '0',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
    },
    customerMute: {
      left: '0',
      [theme.breakpoints.down('xs')]: {
        left: '0',
      },
      '.mobile--forced &': {
        left: '0',
      },
    },
    nullHalf: {
      height: '14.0625vw !important',
      width: '50% !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%!important',
        height: '100%!important',
      },
      '.mobile--forced &': {
        width: '100%!important',
        height: '100%!important',
      },
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    networkQualityContainer: {
      width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    typeography: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    videoOffIcon: {
      color: '#FFF',
      stroke: 'none',
      width: '27px',
      height: '34px',
      margin: '5px 4px',
      '.grid-5 &, .grid-6 &, .grid-7 &, .grid-8 &, .grid-9 &': {
        width: '18px',
        height: '23px',
      },
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const classes = useStyles();
  const modelParticipant = useParticipantType('model');
  const adminParticipant = useParticipantType('admin');
  const shouldRenderStream = adminParticipant && JSON.parse(adminParticipant.identity).stream;

  const d = JSON.parse(participant.identity);

  return (
    <>
      <article
        className={clsx(classes.container, 'participant-wrapper', {
          [classes.isVideoSwitchedOff]: isVideoSwitchedOff,
          'mobile-participant': d.mob,
        })}
        onClick={onClick}
        data-cy-participant={participant.identity}
      >
        {/* {isVideoEnabled ? ( */}
        <div
          className={
            clsx(classes.infoContainer, { [classes.hideVideo]: !isVideoEnabled }) +
            ' ' +
            (d.role === 'admin' ? classes.left : classes.right)
          }
        >
          <div
            className={
              classes.audioIndicatorWrapper +
              ' ' +
              ((modelParticipant || shouldRenderStream) && d.role === 'customer' ? classes.customerMute : classes.left)
            }
          >
            <AudioLevelIndicator audioTrack={audioTrack} />
            {!isVideoEnabled && <VideoOffIcon className={classes.videoOffIcon} />}
          </div>
        </div>

        {isVideoSwitchedOff && <BandwidthWarning />}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </article>
    </>
  );
}
