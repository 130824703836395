import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconVideoOn(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 26 18" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16146 8.6011e-07H13.8385C14.3657 -1.70213e-05 14.8205 -3.25181e-05 15.195 0.0305712C15.5904 0.0628723 15.9836 0.134188 16.362 0.326982C16.9265 0.614602 17.3854 1.07354 17.673 1.63803C17.8658 2.01641 17.9371 2.40963 17.9694 2.80497C18 3.17954 18 3.6343 18 4.16144V6.46482L24.4453 2.16795C24.7522 1.96338 25.1467 1.94431 25.4719 2.11833C25.797 2.29235 26 2.63121 26 3V15C26 15.3688 25.797 15.7077 25.4719 15.8817C25.1467 16.0557 24.7522 16.0366 24.4453 15.8321L18 11.5352V13.8386C18 14.3657 18 14.8205 17.9694 15.195C17.9371 15.5904 17.8658 15.9836 17.673 16.362C17.3854 16.9265 16.9265 17.3854 16.362 17.673C15.9836 17.8658 15.5904 17.9371 15.195 17.9694C14.8205 18 14.3657 18 13.8386 18H4.16144C3.6343 18 3.17954 18 2.80497 17.9694C2.40963 17.9371 2.01641 17.8658 1.63803 17.673C1.07354 17.3854 0.614602 16.9265 0.326982 16.362C0.134188 15.9836 0.0628723 15.5904 0.0305712 15.195C-3.25181e-05 14.8205 -1.70213e-05 14.3657 8.6011e-07 13.8385V4.16146C-1.70213e-05 3.63431 -3.25181e-05 3.17955 0.0305712 2.80497C0.0628723 2.40963 0.134188 2.01641 0.326982 1.63803C0.614602 1.07354 1.07354 0.614602 1.63803 0.326982C2.01641 0.134188 2.40963 0.0628723 2.80497 0.0305712C3.17955 -3.25181e-05 3.63431 -1.70213e-05 4.16146 8.6011e-07Z"
      />
    </SvgIcon>
  );
}

export default IconVideoOn;
