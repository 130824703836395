import React, { useState, useRef, useCallback } from 'react';
import clsx from 'clsx';
import AboutDialog from '../../AboutDialog/AboutDialog';
// import Button from '@material-ui/core/Button';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
// import MoreIcon from '@material-ui/icons/Settings';
// import SettingsDialog from '../SettingsDialog/SettingsDialog';
// import UserAvatar from '../UserAvatar/UserAvatar';
// import MoreIcon from '@material-ui/icons/Settings';
// import UserAvatar from '../UserAvatar/UserAvatar';
import SettingsIcon from './SettingsIcon';

import { useAppState } from '../../../state';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: '#ffffff',
    },
    black: {
      color: '#000000',
    },
    icon: {
      marginLeft: '48px',
    },
  })
);

export default function Menu() {
  const { user, signOut } = useAppState();
  const { room, localTracks } = useVideoContext();
  const roomState = useRoomState();
  const classes = useStyles();
  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleSignOut = useCallback(() => {
    room.disconnect?.();
    localTracks.forEach(track => track.stop());
    signOut?.();
  }, [room.disconnect, localTracks, signOut]);

  return (
    <div ref={anchorRef}>
      <IconButton
        className={clsx([classes.icon], {
          [classes.white]:
            roomState === 'disconnected' && process.env.REACT_APP_LOGO_DARK && process.env.REACT_APP_LOGO_LIGHT,
          [classes.black]: !(
            roomState === 'disconnected' &&
            process.env.REACT_APP_LOGO_DARK &&
            process.env.REACT_APP_LOGO_LIGHT
          ),
        })}
        onClick={() => setSettingsOpen(state => !state)}
      >
        <SettingsIcon />
      </IconButton>
      <MenuContainer open={menuOpen} onClose={() => setMenuOpen(state => !state)} anchorEl={anchorRef.current}>
        {user?.displayName && <MenuItem disabled>{user.displayName}</MenuItem>}
        <MenuItem onClick={() => setAboutOpen(true)}>About</MenuItem>
        <MenuItem onClick={() => setSettingsOpen(true)}>Settings</MenuItem>
        {user && <MenuItem onClick={handleSignOut}>Logout</MenuItem>}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </div>
  );
}
