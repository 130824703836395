import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { isMobile, isIOS } from '../../utils';

import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleMessagesButton from '../Buttons/ToggleMessagesButton/ToggleMessagesButton';
import ToggleVideoBgPlayButton from '../Buttons/ToggleBgVideoPlayButton/ToggleBgVideoPlayButton';
import useIsUserActive from './useIsUserActive/useIsUserActive';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 0px)',
      bottom: '50px',
      zIndex: 5,
      transition: 'opacity 1.2s, visibility 0s 1.2s',
      opacity: 0,
      visibility: 'hidden',
      maxWidth: 'min-content',
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
      },
      [theme.breakpoints.down('md')]: {
        bottom: `30px`,
      },
      [theme.breakpoints.down('xs')]: {
        bottom: '50px',
      },
    },
  })
);

export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();

  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';
  const {
    isSharingScreen,
    room: { localParticipant },
    isBgVideoPlaying,
  } = useVideoContext();

  // console.log('localParticipant', localParticipant.identity);
  const vibeChatOn = JSON.parse(localParticipant.identity).chat;

  const isGhost = JSON.parse(localParticipant.identity).role === 'ghost';

  return (
    <div className={clsx(classes.container, { showControls })}>
      {roomState !== 'disconnected' && vibeChatOn && (
        <>
          <ToggleMessagesButton />
        </>
      )}
      {JSON.parse(localParticipant.identity).role === 'admin' && !isGhost && (
        <>
          {!isBgVideoPlaying && !isMobile && !isIOS && <ToggleScreenShareButton disabled={isReconnecting} />}
          {!isSharingScreen && process.env.REACT_APP_VIMEO_ID && <ToggleVideoBgPlayButton />}
        </>
      )}
      {/* {JSON.parse(localParticipant.identity).role === 'customer' && <ToggleVideoBgPlayButton />} */}
      {!isGhost && <ToggleAudioButton disabled={isReconnecting} />}
      {!isGhost && <ToggleVideoButton disabled={isReconnecting} />}
      {roomState !== 'disconnected' && (
        <>
          <EndCallButton />
        </>
      )}
    </div>
  );
}
