import React from 'react';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import ParticipantForm from './components/ParticipantForm/ParticipantForm';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import Logo from './components/Logo/Logo';
import MenuBar from './components/MenuBar/MenuBar';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';

const RoomContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr',
});

const LocalContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  // paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  // background: 'black',
  // zIndex: 1100,
  [theme.breakpoints.down('sm')]: {
    // paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // marginTop: '5vh',
      },
    },
  })
);

export default function App(props: { invalidLocation: boolean }) {
  const classes = useStyles();
  const roomState = useRoomState();

  const Container = roomState === 'disconnected' ? LocalContainer : RoomContainer;

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const mobileForced = window.location.search.toLowerCase().includes('mobileview=true');
  const hideLogoGear = window.location.search.toLowerCase().includes('hidelogogear=true');

  return (
    // <Container style={{ height }}>
    <Container
      className={clsx({
        'mobile--forced': mobileForced && roomState !== 'disconnected',
        'room-disconnected': roomState === 'disconnected',
        'hide-logo': hideLogoGear,
        'hide-gear': hideLogoGear,
      })}
      style={{ height }}
    >
      <Logo />
      <MenuBar />
      <Main className={clsx(classes.main)}>
        {roomState === 'disconnected' ? <ParticipantForm invalidLocation={props.invalidLocation} /> : <Room />}
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
