import { useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';

export default function DataTrack({ track }: { track: IDataTrack }) {
  useEffect(() => {
    const handleMessage = (message: string) => {
      // handle message here. Determine if it's a play/stop/volume action.
      // console.log('in handlemessage', message);

      if (message.includes('VibeVideoPlay')) {
        const parts = message.split('-');
        if (!parts[1]) return;
        // console.log('triggering VibeVideoPlay from a remote order', parts[1]);
        document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: Number(parts[1]) } }));
      }

      if (message.includes('VibeVideoStop')) {
        // console.log('triggering STOP from a remote order');
        document.dispatchEvent(new CustomEvent('VibeVideoStop'));
      }

      if (message.includes('VibeMuteAll')) document.dispatchEvent(new CustomEvent('VibeMuteAll'));

      if (message.includes('VibeUnmuteAll')) document.dispatchEvent(new CustomEvent('VibeUnmuteAll'));

      if (message.includes('VibeMaxVolumeLock')) {
        const parts = message.split('-');
        if (!parts[1]) return;
        document.dispatchEvent(new CustomEvent('VibeMaxVolumeLock', { detail: { maxVolume: Number(parts[1]) } }));
      }

      if (message.includes('VibeHideAll')) document.dispatchEvent(new CustomEvent('VibeHideAll'));

      if (message.includes('VibeShowAll')) document.dispatchEvent(new CustomEvent('VibeShowAll'));

      if (message.includes('VibeVideoPause')) document.dispatchEvent(new CustomEvent('VibeVideoPause'));
    };

    track.on('message', handleMessage);

    return () => {
      track.off('message', handleMessage);
    };
  }, [track]);

  return null; // This component does not return any HTML, so we will return 'null' instead.
}
