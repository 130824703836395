import { useEffect, useRef } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useParticipants from '../useParticipants/useParticipants';

export default function useAdminDatatrackSend() {
  const { room } = useVideoContext();
  const [localDataTrackPublication] =
    room.state === 'connected' ? [...room.localParticipant.dataTracks.values()] : [undefined];
  const localDataTrack = useRef(localDataTrackPublication);
  const messageInterval = useRef<NodeJS.Timeout>();
  const participants = useParticipants();
  const currentLocalDataTrack = localDataTrack.current;

  const sendMessage = (message: string, delay?: number) => {
    const send = () => {
      if (localDataTrack.current) {
        // console.log('sending message', message, 'direct');
        localDataTrack.current.track.send(message);
      } else if (participants.length) {
        // console.log('sending message', message, 'with interval');
        if (messageInterval.current) clearInterval(messageInterval.current);
        let times = 0;
        messageInterval.current = setInterval(() => {
          // console.log('in interval for message', message);
          times++;
          if (localDataTrack.current) {
            localDataTrack.current.track.send(message);
            if (messageInterval.current && times > 1) clearInterval(messageInterval.current);
          }
        }, 3000);
      }
    };

    if (delay) {
      setTimeout(send, delay);
    } else {
      send();
    }
  };

  // const sendDelayedMessage = (message: string) => {
  //   if (messageInterval.current) clearInterval(messageInterval.current);
  //   let times = 0;
  //   messageInterval.current = setInterval(() => {
  //     console.log('in interval for delayed message', message);
  //     times++;
  //     if (localDataTrack.current) {
  //       localDataTrack.current.track.send(message);
  //     }
  //     if (messageInterval.current && times > 1) clearInterval(messageInterval.current);
  //   }, 3000);
  // };

  useEffect(() => {
    localDataTrack.current = localDataTrackPublication;
  });

  return [sendMessage, currentLocalDataTrack] as const;
}
