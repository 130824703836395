import { useState, useEffect } from 'react';
import NoiseGifDefault from './noise.gif';

export default function useNoiseGif() {
  const [noiseGif, setNoiseGif] = useState(NoiseGifDefault);

  useEffect(() => {
    if (process.env.REACT_APP_NOISE_GIF) setNoiseGif(process.env.REACT_APP_NOISE_GIF);
  }, []);

  return noiseGif;
}
