import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';

import { isMobile, isIOS } from '../../../utils';

export default function useIsUserActive() {
  const [isUserActive, setIsUserActive] = useState(true);
  const timeoutIDRef = useRef(0);

  useEffect(() => {
    let mounted = true;
    const handleUserActivity = throttle((e?: Event) => {
      // console.log('in handle user activity, e:', e && e.defaultPrevented, e && e.target, e);
      if (!mounted || (e && e.defaultPrevented) || (e && e.type === 'mousemove' && (isMobile || isIOS))) return;
      setIsUserActive(true);
      clearTimeout(timeoutIDRef.current);
      const timeoutID = window.setTimeout(() => setIsUserActive(false), 5000);
      timeoutIDRef.current = timeoutID;
    }, 500);

    handleUserActivity();
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutIDRef.current);
      mounted = false;
    };
  }, []);

  return isUserActive;
}
