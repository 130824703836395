import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import CallEnd from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { isIOS } from '../../../utils';

import IconClose from '../../Buttons/IconClose';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#FFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    arrow: {
      color: '#FFF',
    },
    fab: {
      backgroundColor: '#000000',
      color: '#ffffff',
      margin: theme.spacing(1),
      '&.device:hover, &.device:focus': {
        backgroundColor: '#000000',
        color: '#ffffff',
      },
      '&:hover': {
        [theme.breakpoints.down('xs')]: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    closeIcon: {
      width: '30px',
      height: '30px',
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <Tooltip
      title={'EXIT'}
      onClick={() => room.disconnect()}
      placement="top"
      PopperProps={{ disablePortal: true }}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow, touch: classes.tooltipTouch }}
      arrow
    >
      <div>
        <Fab className={clsx(classes.fab, { device: isIOS })}>
          <IconClose className={classes.closeIcon} />
        </Fab>
      </div>
    </Tooltip>
  );
}
