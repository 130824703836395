import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { isIOS } from '../../utils';

import Menu from './Menu/Menu';
import MuteAll from './MuteAll';
import HideAll from './HideAll';

import VolumeBar from './VolumeBar';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import useParticipants from '../../hooks/useParticipants/useParticipants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: 'unset',
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      [theme.breakpoints.down('xs')]: {
        opacity: '0',
      },
      '.mobile--forced &, .hide-gear &, &.device': {
        opacity: '0',
      },
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const {
    room,
    room: { localParticipant },
    showSettings,
    isBgVideoPlaying,
  } = useVideoContext();
  const isAdmin = room.state === 'connected' && JSON.parse(localParticipant.identity).role === 'admin';

  return (
    <AppBar className={classes.container} position="absolute">
      <Toolbar className={classes.toolbar}>
        <div
          className={clsx({
            [classes.rightButtonContainer]: true,
            device: isIOS,
          })}
        >
          {isBgVideoPlaying && isAdmin && <VolumeBar />}
          {/* {isAdmin && <VolumeBar />} */}

          {isAdmin && room.state === 'connected' && <MuteAll />}
          {isAdmin && room.state === 'connected' && <HideAll />}

          {showSettings && <Menu />}
        </div>
      </Toolbar>
    </AppBar>
  );
}
