import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    // brand: string;
    // footerHeight: number;
    mobileTopBarHeight: number;
    // mobileFooterHeight: number;
    // sidebarMobilePadding: number;
    participantBorderWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    // brand: string;
    // footerHeight: number;
    mobileTopBarHeight: number;
    // mobileFooterHeight: number;
    // sidebarMobilePadding: number;
    participantBorderWidth: number;
  }
}

let fonts = ['NB Akademie Std', 'Source Sans Pro', 'Arial', 'sans-serif'];
if (process.env.REACT_APP_MODE === 'prada') fonts.unshift('Benton');
if (process.env.REACT_APP_MODE === 'gucci') fonts.unshift('GucciSans');

export default createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 721,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#EEEEEE',
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#000000',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0)',
          color: '#ffffff',
        },
      },
      contained: {
        '&:hover': {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
      outlined: {
        '&:hover': {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    MuiFab: {
      root: {
        backgroundColor: '#ffffff',
        color: '#000000',
        '&:hover': {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
  },
  typography: {
    fontFamily: fonts.join(','),
    button: {
      fontFamily: fonts.join(','),
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1600,
    snackbar: 1400,
    tooltip: 1500,
  },
  sidebarWidth: window.innerWidth / 2,
  sidebarMobileHeight: 90,
  // sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
});
