import React from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import useRoomState from '../../hooks/useRoomState/useRoomState';

const logoSizes = process.env.REACT_APP_LOGO_SIZES && process.env.REACT_APP_LOGO_SIZES.split(',');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: (logoSizes && `${logoSizes[0]}px`) || '100px',
      height: 'auto',
      position: 'absolute',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 2,
      [theme.breakpoints.down('xs')]: {
        width:
          logoSizes && logoSizes[1] ? `${logoSizes[1]}px` : logoSizes && logoSizes[0] ? `${logoSizes[0]}px` : '100px',
        zIndex: 0,
      },
      '.mobile--forced &, .hide-logo &': {
        width:
          logoSizes && logoSizes[1] ? `${logoSizes[1]}px` : logoSizes && logoSizes[0] ? `${logoSizes[0]}px` : '100px',
        zIndex: 0,
        opacity: 0,
      },
      '.room-disconnected &': {
        [theme.breakpoints.down('xs')]: {
          zIndex: 2,
        },
        '.mobile--forced &, .hide-logo &': {
          zIndex: 2,
        },
      },
    },
  })
);

export default function Logo() {
  const classes = useStyles();
  const roomState = useRoomState();

  if (roomState === 'disconnected' && process.env.REACT_APP_LOGO_LIGHT) {
    return <img className={clsx(classes.logo)} src={process.env.REACT_APP_LOGO_LIGHT} alt="" />;
  } else {
    return <img className={clsx(classes.logo)} src={process.env.REACT_APP_LOGO_DARK} alt="" />;
  }
}
