import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import IconMessageBubble from '../IconMessageBubble';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      position: 'relative',
      '&:hover': {
        background: '#00FFFF',
        color: '#000',
      },
      [theme.breakpoints.down('xs')]: {
        '&:hover': {
          backgroundColor: '#FFF',
          color: '#000',
        },
      },
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
    fabLabel: {
      pointerEvents: 'none',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#FFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    blueTooltip: {
      background: '#00FFFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    blueArrow: {
      color: '#00FFFF',
    },
    whiteArrow: {
      color: '#FFF',
    },
    circle: {
      position: 'absolute',
      width: '14px',
      height: '14px',
      right: '9px',
      top: '10px',
      background: '#00FFFF',
      borderRadius: '100%',
      border: '2px solid #FFF',
    },
  })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();

  const {
    room: { localParticipant },
  } = useVideoContext();

  const [newMessages, setNewMessages] = useState(0);

  const trackNewMessages = JSON.parse(localParticipant.identity).role === 'admin';

  useEffect(() => {
    const newMessageListener = (e: CustomEvent) => {
      const { detail } = e;
      // console.log('new messages', detail);
      setNewMessages(detail);
    };

    if (trackNewMessages) document.addEventListener('vibechat_new_message', newMessageListener as EventListener);

    return () => {
      if (trackNewMessages) document.removeEventListener('vibechat_new_message', newMessageListener as EventListener);
    };
  });

  return (
    <Tooltip
      title={trackNewMessages && newMessages ? `${newMessages} NEW` : 'CHAT'}
      placement="top"
      PopperProps={{ disablePortal: true }}
      classes={{
        tooltip: trackNewMessages && newMessages ? classes.blueTooltip : classes.tooltip,
        arrow: trackNewMessages && newMessages ? classes.blueArrow : classes.whiteArrow,
        touch: classes.tooltipTouch,
      }}
      arrow
      // open
    >
      <div>
        <Fab className={classes.fab} classes={{ label: classes.fabLabel }} id="vibe-chat-trigger">
          <IconMessageBubble />
          {trackNewMessages && newMessages ? <span className={classes.circle}></span> : <></>}
        </Fab>
      </div>
    </Tooltip>
  );
}
