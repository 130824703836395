import useParticipantType from '../useParticipantType/useParticipantType';
// import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';

export default function useMainSpeaker() {
  const modelParticipant = useParticipantType('model');
  // const screenShareParticipant = useScreenShareParticipant();

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return modelParticipant;
}
