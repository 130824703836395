import { useEffect, useState } from 'react';

export default function useBgVideoPlaying() {
  const [isBgVideoPlaying, setIsBgVideoPlaying] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number>();

  useEffect(() => {
    // if (!localDataTrackPublication) return;
    const handlePlay = (e: CustomEvent) => {
      setIsBgVideoPlaying(true);
      // console.log('in handle VibeVideoPlay', e.detail.startTime);
      setStartTime(e.detail.startTime);
    };
    const handleStop = () => {
      // console.log('in handlevibestop');
      setIsBgVideoPlaying(false);
      setStartTime(undefined);
    };

    document.addEventListener('VibeVideoPlay', handlePlay as EventListener);
    document.addEventListener('VibeVideoStop', handleStop);

    return () => {
      document.removeEventListener('VibeVideoPlay', handlePlay as EventListener);
      document.removeEventListener('VibeVideoStop', handleStop);
    };
  }, [isBgVideoPlaying]);

  return [isBgVideoPlaying, startTime, setStartTime] as const;
}
