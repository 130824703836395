import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import clsx from 'clsx';

import { isMobile, isIOS } from '../../../utils';

import IconPlay from '../../Buttons/IconPlay';
import IconStop from '../../Buttons/IconStop';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
// import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useAdminDatatrackSend from '../../../hooks/useAdminDatatrackSend/useAdminDatatrackSend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stop: {
      width: '20px',
      height: '20px',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#FFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    arrow: {
      color: '#FFF',
    },
    fab: {
      margin: theme.spacing(1),
      backgroundColor: '#ffffff',
      color: '#000000',
      '&.device:hover, &.device:focus': {
        backgroundColor: '#ffffff',
        color: '#000000',
      },
      '&:hover': {
        backgroundColor: '#ff5860',
        [theme.breakpoints.down('xs')]: {
          backgroundColor: '#ffffff',
          color: '#000000',
        },
      },
    },
    fabDisabled: {
      '&.device:hover, &.device:focus': {
        backgroundColor: '#ff5860',
      },
      backgroundColor: '#ff5860',
      '&:hover': {
        [theme.breakpoints.down('xs')]: {
          backgroundColor: '#ff5860',
          color: '#FFFFFF',
        },
      },
    },
  })
);

export default function ToggleBgVideoPlayButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const { room, isBgVideoPlaying, videoStartedAt } = useVideoContext();

  const [sendMessage, currentLocalDataTrack] = useAdminDatatrackSend();

  const handleClick = () => {
    if (!isBgVideoPlaying) {
      const adminStartTime = Date.now();
      document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: adminStartTime } }));
      sendMessage(`VibeVideoPlay-${adminStartTime}`);
    } else {
      // trigger the local Admin video stop
      document.dispatchEvent(new CustomEvent('VibeVideoStop'));
      sendMessage('VibeVideoStop');
    }
  };

  useEffect(() => {
    const sendCurrentStatus = () => {
      if (isBgVideoPlaying === true && videoStartedAt && currentLocalDataTrack) {
        // Send the message -- giving some time for the new user to connect data track
        sendMessage(`VibeVideoPlay-${videoStartedAt}`, 3000);
      } else if (isBgVideoPlaying === false && currentLocalDataTrack) {
        // Send the message to stop video to the new participants.
        sendMessage('VibeVideoStop', 3000);
      }
    };

    // when participants change - i.e when someone joins the room, admin sends the message of the state of the bgvideo
    room.on('participantConnected', sendCurrentStatus);

    return () => {
      room.off('participantConnected', sendCurrentStatus);
    };
  });

  return (
    <Tooltip
      arrow
      title={isBgVideoPlaying ? 'STOP VIDEO' : 'PLAY VIDEO'}
      placement="top"
      PopperProps={{ disablePortal: true }}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow, touch: classes.tooltipTouch }}
    >
      <div>
        <Fab
          className={clsx(classes.fab, {
            [classes.fabDisabled]: isBgVideoPlaying,
            device: isIOS || isMobile,
          })}
          onClick={handleClick}
          disabled={props.disabled}
        >
          {isBgVideoPlaying ? <IconStop className={classes.stop} /> : <IconPlay />}
        </Fab>
      </div>
    </Tooltip>
  );
}
