import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconPause(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 72 88" fill="none" {...props}>
      <rect width="24" height="88" rx="12" />
      <rect x="48" width="24" height="88" rx="12" />
    </SvgIcon>
  );
}

export default IconPause;
