import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled } from '@material-ui/core/styles';

//bring the inner height lib here and give the container the height of that

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'block',
  // display: 'grid',
  // gridTemplateColumns: `1fr 1fr`,
  // gridTemplateAreas: '". participantList"',
  // gridTemplateRows: `100%`,
  [theme.breakpoints.down('xs')]: {
    gridTemplateAreas: '". participantList"',
    gridTemplateColumns: `auto`,
    gridTemplateRows: `calc(100% - ${theme.sidebarMobileHeight + 12}px) ${theme.sidebarMobileHeight + 6}px`,
    gridGap: '6px',
  },
}));

export default function Room() {
  return (
    <Container>
      <ParticipantStrip />
    </Container>
  );
}
