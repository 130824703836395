import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconClose(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 68 68" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9706 17.0296C49.4085 15.4675 46.8758 15.4675 45.3137 17.0296L34 28.3434L22.6863 17.0296C21.1242 15.4675 18.5915 15.4675 17.0294 17.0296C15.4673 18.5917 15.4673 21.1244 17.0294 22.6865L28.3431 34.0002L17.0294 45.3139C15.4673 46.876 15.4673 49.4087 17.0294 50.9708C18.5915 52.5329 21.1242 52.5329 22.6863 50.9708L34 39.6571L45.3137 50.9708C46.8758 52.5329 49.4085 52.5329 50.9706 50.9708C52.5327 49.4087 52.5327 46.876 50.9706 45.3139L39.6569 34.0002L50.9706 22.6865C52.5327 21.1244 52.5327 18.5917 50.9706 17.0296Z"
      />
    </SvgIcon>
  );
}

export default IconClose;
