import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useAdminDatatrackSend from '../../hooks/useAdminDatatrackSend/useAdminDatatrackSend';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '20px',
      padding: '13px 17px 12px',
      color: '#ffffff',
      textTransform: 'uppercase',
      background: '#000',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '13px',
      outline: 'none',
      border: 'none',
      marginRight: '10px',
      cursor: 'pointer',
      // fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    hiddenAll: {
      background: '#FF6666',
    },
  })
);

export default function HideAll() {
  const classes = useStyles();
  const { room, hideCameras } = useVideoContext();
  const [sendMessage] = useAdminDatatrackSend();

  const handleShowAllClick = useCallback(() => {
    // show all cameras locally for admin
    if (hideCameras) document.dispatchEvent(new CustomEvent('VibeShowAll'));
    sendMessage('VibeShowAll');
  }, [hideCameras, sendMessage]);

  const handleHideAllClick = useCallback(() => {
    // hide all cameras locally for admin
    if (!hideCameras) document.dispatchEvent(new CustomEvent('VibeHideAll'));
    sendMessage('VibeHideAll');
  }, [hideCameras, sendMessage]);

  useEffect(() => {
    const handleParticipantConnected = () => {
      if (hideCameras) {
        sendMessage('VibeHideAll', 3000);
      } else {
        sendMessage('VibeShowAll', 3000);
      }
    };

    room.on('participantConnected', handleParticipantConnected);

    return () => {
      room.off('participantConnected', handleParticipantConnected);
    };
  });

  return (
    <button
      className={clsx(classes.button, { [classes.hiddenAll]: hideCameras })}
      onClick={hideCameras ? handleShowAllClick : handleHideAllClick}
    >
      {hideCameras ? 'Show all' : 'Hide all'}
    </button>
  );
}
