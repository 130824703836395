import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconStop(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 22 22" fill="none" {...props}>
      <rect width="22" height="22" rx="3" />
    </SvgIcon>
  );
}

export default IconStop;
