import { useEffect, useState } from 'react';

export default function useBgVideoPlaying() {
  const [videoVolume, setVideoVolume] = useState({
    volume: 100,
    maxVolume: 100,
  });

  useEffect(() => {
    // if (!localDataTrackPublication) return;
    const handleMaxVolumeLock = (e: CustomEvent) => {
      // console.log('handleMaxVolumeLock', e.detail.maxVolume);
      let newState: any = {
        maxVolume: e.detail.maxVolume,
        volume: e.detail.maxVolume,
      };

      if (videoVolume.volume > e.detail.maxVolume) {
        newState = {
          ...newState,
        };
      }

      setVideoVolume(state => ({
        ...newState,
      }));
    };

    document.addEventListener('VibeMaxVolumeLock', handleMaxVolumeLock as EventListener);

    return () => {
      document.removeEventListener('VibeMaxVolumeLock', handleMaxVolumeLock as EventListener);
    };
  }, [videoVolume.volume]);

  return [videoVolume, setVideoVolume] as const;
}
