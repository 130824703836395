import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { Participant } from 'twilio-video';

/*
  Returns the participant based on type ('model', 'admin', 'customer').
*/

export default function useParticipantType(user: string) {
  const { room } = useVideoContext();
  const [participantType, setParticipantType] = useState<Participant>();

  useEffect(() => {
    let mounted = true;
    if (room.state === 'connected' && mounted) {
      const updateParticipantType = () => {
        if (!mounted) return;
        setParticipantType(
          Array.from<Participant>(room.participants.values())
            // the screenshare participant could be the localParticipant
            .concat(room.localParticipant)
            .find((participant: Participant) => JSON.parse(participant.identity).role === user)
        );
      };

      updateParticipantType();

      room.on('trackPublished', updateParticipantType);
      room.on('trackUnpublished', updateParticipantType);
      room.on('participantConnected', updateParticipantType);
      room.on('participantDisconnected', updateParticipantType);

      // the room object does not emit 'trackPublished' events for the localParticipant,
      // so we need to listen for them here.
      room.localParticipant.on('trackPublished', updateParticipantType);
      room.localParticipant.on('trackUnpublished', updateParticipantType);

      return () => {
        room.off('trackPublished', updateParticipantType);
        room.off('trackUnpublished', updateParticipantType);
        room.off('participantConnected', updateParticipantType);
        room.off('participantDisconnected', updateParticipantType);

        room.localParticipant.off('trackPublished', updateParticipantType);
        room.localParticipant.off('trackUnpublished', updateParticipantType);
        mounted = false;
      };
    }
  }, [room, user]);

  return participantType;
}
