import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
// import Button from '@material-ui/core/Button';
// import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      position: 'relative',
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#FFF',
      color: '#000',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    arrow: {
      color: '#FFF',
    },
  })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = SCREEN_SHARE_TEXT;

  if (disableScreenShareButton) {
    tooltipMessage = STOP_SCREEN_SHARE_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow, touch: classes.tooltipTouch }}
      arrow
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        {/* <Button
          className={classes.button}
          onClick={toggleScreenShare}
          disabled={false}
          startIcon={Boolean(screenShareParticipant) ? <StopScreenShare /> : <ScreenShare />}
          data-cy-share-screen
        >
        </Button> */}
        <Fab className={classes.fab} onClick={toggleScreenShare}>
          {Boolean(screenShareParticipant) ? <StopScreenShare /> : <ScreenShare />}
        </Fab>
      </span>
    </Tooltip>
  );
}
