import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconArrowRight(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9706 22.8283C38.5327 21.2662 38.5327 18.7336 36.9706 17.1715L22.8284 3.02935C21.2663 1.46726 18.7337 1.46726 17.1716 3.02935C15.6095 4.59145 15.6095 7.12411 17.1716 8.68621L28.4853 19.9999L17.1716 31.3136C15.6095 32.8757 15.6095 35.4084 17.1716 36.9705C18.7337 38.5326 21.2663 38.5326 22.8284 36.9705L36.9706 22.8283Z"
      />
      <rect x="2" y="16" width="36" height="8" rx="4" />
    </SvgIcon>
  );
}

export default IconArrowRight;
