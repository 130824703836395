import React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
// import NotFound from './components/NotFound/NotFound';
// import NotSupported from './components/NotSupported/NotSupported';

import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  // const videoSetting =
  //   ParticipantRole === 'm'
  //     ? { width: { ideal: 4096 }, height: { ideal: 2160 }, frameRate: 24 }
  //     : { height: 720, width: 1280, frameRate: 24 };
  // connectionOptions.video = videoSetting;

  const { URLLocation, URLRoomName } = useParams();
  const allowed_locations = process.env.REACT_APP_ALLOW_LOCATIONS && process.env.REACT_APP_ALLOW_LOCATIONS.split(',');

  const allowed_location_rooms =
    process.env.REACT_APP_ALLOW_LOCATIONS_ROOMS && process.env.REACT_APP_ALLOW_LOCATIONS_ROOMS.split(',');

  const invalidLocation = Boolean(
    (URLLocation && allowed_locations && !allowed_locations.includes(URLLocation.toLocaleLowerCase())) ||
      (URLLocation &&
        URLRoomName &&
        allowed_location_rooms &&
        !allowed_location_rooms.includes(`${URLLocation}/${URLRoomName}`))
  );

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App invalidLocation={invalidLocation} />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path="/">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/:URLLocation?/:URLRoomName?/:ParticipantRole?">
            <VideoApp />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
