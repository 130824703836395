import React from 'react';

import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import BackgroundVideo from '../BackgroundVideo/BackgroundVideo';

import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export default function MainParticipant(props: { setVideoSynced?: () => void; startTime?: number }) {
  const mainParticipant = useMainParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const { isBgVideoPlaying } = useVideoContext();

  const videoPriority = 'high';
  const shouldRenderStream = isBgVideoPlaying;

  if (shouldRenderStream) {
    return <BackgroundVideo setVideoSynced={props.setVideoSynced}></BackgroundVideo>;
  }

  return (
    <>
      {screenShareParticipant && (
        <ParticipantTracks
          participant={screenShareParticipant}
          videoPriority={videoPriority}
          enableScreenShare={true}
          videoOnly={true}
        />
      )}

      {mainParticipant && (
        <ParticipantTracks
          participant={mainParticipant}
          videoPriority={videoPriority}
          enableScreenShare={false}
          videoOnly={false}
        />
      )}
    </>
  );
}
