import React, { useCallback, useEffect, useState } from 'react';
import { LocalTrackPublication } from 'twilio-video';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import useParticipants from '../../hooks/useParticipants/useParticipants';
import useAdminDatatrackSend from '../../hooks/useAdminDatatrackSend/useAdminDatatrackSend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '20px',
      padding: '13px 17px 12px',
      color: '#ffffff',
      textTransform: 'uppercase',
      background: '#000',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '13px',
      outline: 'none',
      border: 'none',
      marginRight: '10px',
      cursor: 'pointer',
      // fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    muted: {
      background: '#FF6666',
    },
  })
);

export default function MuteAll() {
  const initialMuted =
    process.env.REACT_APP_MUTED_DEFAULT && process.env.REACT_APP_MUTED_DEFAULT.toLowerCase() === 'true';
  const classes = useStyles();
  const { room } = useVideoContext();
  const [mutedAll, setMutedAll] = useState(initialMuted);
  const [sendMessage] = useAdminDatatrackSend();

  const handleMuteAllClick = useCallback(() => {
    setMutedAll(true);
    sendMessage('VibeMuteAll');
  }, [sendMessage]);

  const handleUnmuteAllClick = () => {
    setMutedAll(false);
    sendMessage('VibeUnmuteAll');
  };

  useEffect(() => {
    const handleTrackPublished = (publication: LocalTrackPublication) => {
      if (initialMuted && publication.track.kind === 'data') handleMuteAllClick();
    };
    room.localParticipant.on('trackPublished', handleTrackPublished);

    return () => {
      room.localParticipant.off('trackPublished', handleTrackPublished);
    };
  });

  useEffect(() => {
    const handleMuteAll = () => {
      setMutedAll(true);
    };

    const handleUnmuteAll = () => {
      setMutedAll(false);
    };

    document.addEventListener('VibeMuteAll', handleMuteAll);
    document.addEventListener('VibeUnmuteAll', handleUnmuteAll);
    return () => {
      document.removeEventListener('VibeMuteAll', handleMuteAll);
      document.removeEventListener('VibeUnmuteAll', handleUnmuteAll);
    };
  });

  useEffect(() => {
    const handleParticipantConnected = () => {
      setTimeout(() => {
        if (mutedAll) {
          handleMuteAllClick();
        } else {
          // handleUnmuteAllClick();
        }
      }, 3000);
    };

    room.on('participantConnected', handleParticipantConnected);

    return () => {
      room.off('participantConnected', handleParticipantConnected);
    };
  }, [mutedAll, room, handleMuteAllClick]);

  return (
    <button
      className={clsx(classes.button, { [classes.muted]: mutedAll })}
      onClick={mutedAll ? handleUnmuteAllClick : handleMuteAllClick}
    >
      {mutedAll ? 'Unmute' : 'Mute all'}
    </button>
  );
}
