import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import TapForSound from '../Buttons/TapForSound';
import IconPlay from '../Buttons/IconPlay';
import IconPause from '../Buttons/IconPause';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useAdminDatatrackSend from '../../hooks/useAdminDatatrackSend/useAdminDatatrackSend';

import { isMobile, isIOS } from '../../utils';

declare var Vimeo: any;

declare global {
  interface Window {
    vimeoPlayer: any;
  }
}

interface LocationVimeoIds {
  location: string;
  id: string;
}

interface ParamTypes {
  URLLocation: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: 2,
      background: 'transparent',
      border: 'none',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover, &:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    circle: {
      position: 'relative',
      width: '240px',
      height: '240px',
      // left: '50%',
      // top: '50%',
      // transform: 'translateX(-50%), translateY(-50%)',
      backgroundColor: 'rgba(17, 17, 17, 0.2)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      transition: 'opacity 0.2s ease-in',
      opacity: '0',
      '&.show': {
        opacity: '1',
      },
      [theme.breakpoints.down('xs')]: {
        width: '60px',
        height: '60px',
      },
    },
    playIcon: {
      width: '88px',
      height: '80px',
      color: '#fff',
      [theme.breakpoints.down('xs')]: {
        width: '40px',
        height: '40px',
      },
    },
    pauseIcon: {
      width: '72px',
      height: '88px',
      color: '#fff',
      [theme.breakpoints.down('xs')]: {
        width: '40px',
        height: '40px',
      },
    },
  })
);

export default function BackgroundVideo(props: { setVideoSynced?: () => void }) {
  const [videoMuted, setVideoMuted] = useState(isMobile || isIOS);
  const [videoPaused, setVideoPaused] = useState(false);
  const [showIcons, setShowIcons] = useState(false);

  const { URLLocation } = useParams<ParamTypes>();

  const classes = useStyles();
  const [sendMessage] = useAdminDatatrackSend();

  const {
    isBgVideoPlaying,
    videoStartedAt,
    videoVolume,
    room: { localParticipant },
  } = useVideoContext();

  // if (videoStartedAt) console.log('rendering, startTime is', new Date(videoStartedAt).toLocaleString());

  const isAdmin = JSON.parse(localParticipant.identity).role === 'admin';

  let locationVimeoIds: Array<LocationVimeoIds> = [];

  if (process.env.REACT_APP_LOCATION_VIMEO_ID) {
    let locationBgString = process.env.REACT_APP_LOCATION_VIMEO_ID.split(',');

    locationBgString.forEach(l => {
      const parts = l.split('|');
      locationVimeoIds = [...locationVimeoIds, { location: parts[0], id: parts[1] }];
    });
  }

  const locationId = URLLocation && locationVimeoIds.find(l => l.location === URLLocation.toLowerCase());

  const vimeoId = locationId ? locationId.id : process.env.REACT_APP_VIMEO_ID;
  const shouldRenderStream = isBgVideoPlaying;
  const vimeoPlayer = useRef(null) as any;
  const pauseTimeout = useRef<NodeJS.Timeout>();
  const latestStartTime = useRef(videoStartedAt);

  const togglePlayPause = () => {
    setShowIcons(true);
    if (videoPaused) {
      vimeoPlayer.current.play();
    } else {
      vimeoPlayer.current.pause();
    }
  };

  const handleVideoClick = async () => {
    if (!isAdmin) return;
    // to do, admin sends datatrack message
    if ((isMobile || isIOS) && videoMuted) return;
    if (videoPaused) {
      const videoCurrentTime = await vimeoPlayer.current.getCurrentTime();
      const adminStartTime = Date.now() - videoCurrentTime * 1000;

      // console.log('video started at', new Date(adminStartTime).toLocaleString("en-US"));

      document.dispatchEvent(new CustomEvent('VibeVideoPlay', { detail: { startTime: adminStartTime } }));
      sendMessage(`VibeVideoPlay-${adminStartTime}`);
      // sendMessage('VibePlayAfterPause');
    }
    if (!videoPaused) sendMessage('VibeVideoPause');
    togglePlayPause();
  };

  useEffect(() => {
    latestStartTime.current = videoStartedAt;
  });

  useEffect(() => {
    const handleVibeVideoPlay = () => {
      if (videoPaused) {
        // console.log('handleVibeVideoPlay');
        if (pauseTimeout.current) clearTimeout(pauseTimeout.current);
        togglePlayPause();
      }
    };

    const handleVibeVideoPause = () => {
      if (!videoPaused) {
        if (pauseTimeout.current) clearTimeout(pauseTimeout.current);
        togglePlayPause();
      }
    };

    document.addEventListener('VibeVideoPlay', handleVibeVideoPlay);
    document.addEventListener('VibeVideoPause', handleVibeVideoPause);

    return () => {
      document.removeEventListener('VibeVideoPlay', handleVibeVideoPlay);
      document.removeEventListener('VibeVideoPause', handleVibeVideoPause);
    };
  });

  useEffect(() => {
    if (showIcons) {
      setTimeout(() => {
        setShowIcons(false);
      }, 500);
      pauseTimeout.current = setTimeout(() => {
        setVideoPaused(s => !s);
      }, 800);
    }
  }, [showIcons]);

  useEffect(() => {
    if (!isBgVideoPlaying) {
      setVideoMuted(isMobile || isIOS);
    }
    // else {
    //   console.log('here', document.getElementById('vimeoScript'));
    //   if (document.getElementById('vimeoScript') !== null) {
    //     document.getElementById('vimeoScript').onload();
    //   }
    // }
  }, [isBgVideoPlaying]);

  useEffect(() => {
    let script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.id = 'vimeoScript';
    let volumeAttached = false;
    let handleVolumeDown = () => {};
    let handleVolumeUp = () => {};
    let unmuteOnClick = (e: Event) => {};
    let handleTimeUpdate = (data: any) => {};
    let handleLoaded = (data: any) => {};

    let vimeoIframe = document.getElementById('vimeoIframe');

    if (shouldRenderStream && vimeoIframe) {
      script.onload = function() {
        //do stuff with the script
        vimeoPlayer.current = new Vimeo.Player(vimeoIframe);
        window.vimeoPlayer = vimeoPlayer.current;
        const mainParticipantContainer = document.getElementById('mainParticipantContainer');

        const setVolume = (value: Number) => {
          if (!vimeoPlayer.current) return;
          if (value > videoVolume.maxVolume) vimeoPlayer.current.setVolume(videoVolume.maxVolume);
          else vimeoPlayer.current.setVolume(value);
        };

        const attachVolumeHandlers = () => {
          volumeAttached = true;
          handleVolumeUp = () => {
            // console.log('volumeUp', vimeoPlayer.getVolume());
            vimeoPlayer.current.getVolume().then((volume: number) => setVolume(volume + 0.1));
          };

          handleVolumeDown = () => {
            vimeoPlayer.current.getVolume().then((volume: number) => setVolume(volume - 0.1));
          };

          // add event listeners for volume up, volume down
          document.addEventListener('VibeVideoVolumeUp', handleVolumeUp);
          document.addEventListener('VibeVideoVolumeDown', handleVolumeDown);
        };

        const setHeight = () => {
          Promise.all([vimeoPlayer.current.getVideoWidth(), vimeoPlayer.current.getVideoHeight()]).then(function(
            dimensions
          ) {
            const videoWidth = dimensions[0];
            const videoHeight = dimensions[1];
            const containerWidth = mainParticipantContainer!.offsetWidth;
            const containerHeight = mainParticipantContainer!.offsetHeight;

            const videoRatio = videoWidth / videoHeight;
            const containerRatio = containerWidth / containerHeight;

            if (containerRatio === videoRatio) return;

            if (containerRatio > videoRatio) {
              const calculatedH = ((containerWidth * videoHeight) / videoWidth).toFixed(2);
              if (`${calculatedH}px` !== vimeoIframe!.style.width) {
                vimeoIframe!.style.height = `${calculatedH}px`;
                vimeoIframe!.style.width = '100%';
              }
            } else {
              const calculatedW = ((containerHeight * videoWidth) / videoHeight).toFixed(2);
              if (`${calculatedW}px` !== vimeoIframe!.style.width) {
                vimeoIframe!.style.width = `${calculatedW}px`;
                vimeoIframe!.style.height = '100%';
              }
            }
          });
        };

        unmuteOnClick = (e: Event) => {
          if (!vimeoPlayer.current || (!isMobile && !isIOS)) return;
          // console.log('unmute handler');
          e.preventDefault();
          vimeoPlayer.current.pause();
          vimeoPlayer.current.setMuted(false);
          setVolume(1);
          setTimeout(() => {
            vimeoPlayer.current.play();
          }, 200);
          setVideoMuted(false);
        };

        if (videoMuted) {
          document.addEventListener('click', unmuteOnClick, { once: true });
        }

        const showVideo = () => {
          vimeoIframe!.style.opacity = '1';
          if (!isMobile && !isIOS) vimeoPlayer.current.setVolume(1);
          props.setVideoSynced && props.setVideoSynced();
        };

        let counter = 0;

        const setTime = (data: any) => {
          const isVideoSynced = vimeoIframe!.style.opacity === '1';
          counter++;
          // if video is synced, check every 25 calls to avoid stuck on loop behavior.
          if (!latestStartTime.current || !isBgVideoPlaying || (isVideoSynced && counter % 25 !== 0)) return;
          const now = new Date();
          let secondsSinceStartTime = now.getMinutes() * 60 + now.getSeconds(); // fallback, seconds since hour
          if (latestStartTime.current) secondsSinceStartTime = (Date.now() - latestStartTime.current) / 1000;
          const offset = secondsSinceStartTime % Math.round(data.duration);
          if (Math.abs(data.seconds - offset) > 1 && offset > 1 && offset < data.duration) {
            // console.log('seconds since start', secondsSinceStartTime, 'starttime', latestStartTime.current);
            console.log('Vibe video synced');
            vimeoPlayer.current.setCurrentTime(offset);
            if (vimeoIframe!.style.opacity !== '1' && counter >= 3) {
              showVideo();
            }
          } else {
            if (vimeoIframe!.style.opacity !== '1' && offset) {
              showVideo();
            }
          }
          setHeight();
        };

        handleLoaded = (data: any) => {
          setHeight();
          if (!volumeAttached) attachVolumeHandlers();
        };

        vimeoPlayer.current.on('loaded', handleLoaded);

        handleTimeUpdate = (data: any) => {
          setTime(data);
        };

        vimeoPlayer.current.on('timeupdate', handleTimeUpdate);
      };

      if (!document.getElementById('vimeoScript')) {
        document.head.appendChild(script);
      }
    }

    return () => {
      // if (!shouldRenderStream && document.getElementById('vimeoScript')) {
      //   console.log('removing script from doc')
      //   document.head.removeChild(script);
      // }
      if (videoMuted) {
        document.removeEventListener('click', unmuteOnClick);
      }
      if (volumeAttached) {
        document.removeEventListener('VibeVideoVolumeUp', handleVolumeUp);
        document.removeEventListener('VibeVideoVolumeDown', handleVolumeDown);
      }
      // if (vimeoPlayer.current) {
      //   vimeoPlayer.current.off('timeupdate', handleTimeUpdate);
      //   vimeoPlayer.current.off('loaded', handleLoaded);
      // }
    };
  });

  useEffect(() => {
    return () => {
      const script = document.getElementById('vimeoScript');
      if (script) {
        // console.log('removing script from doc');
        document.head.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (!isBgVideoPlaying) return;

    const setVolume = (value: number) => {
      // console.log('in set volume', value, 'maxVolume is', videoVolume.maxVolume)
      if (!vimeoPlayer.current) return;
      if (value > videoVolume.maxVolume) vimeoPlayer.current.setVolume(videoVolume.maxVolume / 100);
      else vimeoPlayer.current.setVolume(value / 100);
    };

    setVolume(videoVolume.volume);
  }, [videoVolume, isBgVideoPlaying, vimeoPlayer]);

  return (
    <>
      <button onClick={handleVideoClick} className={clsx(classes.button, { [classes.pointer]: isAdmin })}>
        <span
          className={clsx(classes.circle, {
            show: showIcons,
          })}
        >
          {videoPaused ? (
            <IconPlay className={classes.playIcon}></IconPlay>
          ) : (
            <IconPause className={classes.pauseIcon}></IconPause>
          )}
        </span>
      </button>
      <iframe
        id="vimeoIframe"
        title="vimeoIframe"
        src={'https://player.vimeo.com/video/' + vimeoId + `?&controls=0&autoplay=1&muted=1&loop=1&playsinline=1`}
        style={{
          zIndex: 1,
          position: 'absolute',
          top: '0',
          left: '50%',
          bottom: '0',
          right: '0',
          transform: 'translateX(-50%)',
          width: '100%',
          height: '100%',
          border: 'none',
          margin: '0',
          padding: '0',
          overflow: 'hidden',
          opacity: '0',
        }}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
      ></iframe>
      {videoMuted && <TapForSound></TapForSound>}
    </>
  );
}
