import { LocalAudioTrack } from 'twilio-video';
import { useCallback, useEffect, useState } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';
import useParticipantType from '../../hooks/useParticipantType/useParticipantType';

export default function useLocalAudioToggle() {
  const { localTracks, room } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;

  const [mutedAll, setMutedAll] = useState(false);
  const [localMuted, setLocalMuted] = useState(audioTrack && !audioTrack.isEnabled);
  const adminParticipant = useParticipantType('admin');

  const isEnabled = useIsTrackEnabled(audioTrack);
  const mutedDefault =
    process.env.REACT_APP_MUTED_DEFAULT && process.env.REACT_APP_MUTED_DEFAULT.toLowerCase() === 'true';
  const isCustomer = room.state === 'connected' && JSON.parse(room.localParticipant.identity).role === 'customer';

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
      setLocalMuted(!audioTrack.isEnabled);
    }
  }, [audioTrack]);

  useEffect(() => {
    if (!adminParticipant) {
      document.dispatchEvent(new CustomEvent('VibeUnmuteAll'));
    }
  }, [adminParticipant]);

  useEffect(() => {
    const handleMuteAll = () => {
      if (audioTrack) {
        audioTrack.disable();
        setMutedAll(true);
      }
    };

    const handleUnmuteAll = () => {
      if (audioTrack) {
        if (!localMuted) audioTrack.enable();
        setMutedAll(false);
      }
    };

    if (!isCustomer) return;

    document.addEventListener('VibeMuteAll', handleMuteAll);
    document.addEventListener('VibeUnmuteAll', handleUnmuteAll);

    return () => {
      if (!isCustomer) return;
      document.removeEventListener('VibeMuteAll', handleMuteAll);
      document.removeEventListener('VibeUnmuteAll', handleUnmuteAll);
    };
  }, [audioTrack, isCustomer, localMuted]);

  useEffect(() => {
    // customers are muted by default upon entering the room if config variable mutedDefault
    if (isCustomer && mutedDefault) document.dispatchEvent(new CustomEvent('VibeMuteAll'));
  }, [isCustomer, mutedDefault]);

  return [isEnabled, toggleAudioEnabled, mutedAll] as const;
}
